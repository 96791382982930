import React, { useEffect } from "react";
import moment from "moment";
import { getApiData } from "../Services";
import { filter } from "lodash";

// export const SOCKET_URL = "wss://api.taprocket.in";
// export const API_URL =
//   window.location.host.includes("dev.taprocket.in") ||
//     window.location.host.includes("localhost")
//     ? "https://api.taprocket.in/"
//     : "https://api.taprocket.in/";

export const SOCKET_URL = "wss://dev-api.taprocket.in";
export const API_URL =
  window.location.host.includes("dev.taprocket.in") ||
  window.location.host.includes("localhost")
    ? "https://dev-api.taprocket.in/"
    : "https://api.taprocket.in/";

export const USER_IMAGE =
  "https://chhotu-bucket.s3.ap-south-1.amazonaws.com/users/default.png";

export const PER_PAGE = 20;

export const sizePerPageList = [
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];

export const gsts = [
  { value: "0", label: "0" },
  { value: "5", label: "5%" },
  { value: "12", label: "12%" },
  { value: "18", label: "18%" },
  { value: "28", label: "28%" },
];

export const redirection = [
  { label: "Home", value: "Home" },
  { label: "Schedulers", value: "Schedulers" },
  { label: "Account", value: "Account" },
  { label: "Order", value: "Order" },
  { label: "My Offices", value: "MyLocations" },
  { label: "Building List", value: "BuildingList" },
  { label: "Order History", value: "OrderHistory" },
  { label: "Add Credit", value: "AddCredit" },
  { label: "Restaurants", value: "Restaurants" },
  { label: "Billing", value: "Billing" },
  { label: "Feedback", value: "Feedback" },
  { label: "Report Bug", value: "ReportBug" },
  { label: "Contact Us", value: "ContactUs" },
  { label: "Update App", value: "UpdateApp" },
  { label: "Refer", value: "Refer" },
];

export const getPrice = (value) => {
  // Parse the value to ensure it's a number
  const parsedValue = parseFloat(value) || 0;

  // Format the value as Indian currency without using toLocaleString
  const formattedValue =
    "₹" + parsedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedValue;
};

export const UniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const numToSSColumn = (num) => {
  let s = "",
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }
  return s || undefined;
};

export const NumberWithCommas = (n) => {
  const num_ = parseFloat(n || 0);
  if (num_) {
    if (Number(num_) === num_ && num_ % 1 !== 0) {
      return parseFloat(num_)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parseInt(num_)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return num_;
};

export const getRandomValue = () => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const validEmail = (email) => {
  const regex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
  return regex.test(email);
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertDate = (date) => {
  let newDate = "";
  if (date) {
    newDate = new Date(date).toLocaleString();
  }
  return newDate;
};

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};
export const SelectStyle = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #E4E6EF",
    boxShadow: "none",
    "&:hover": {
      border: "1px solid #E4E6EF",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#78829D",
  }),
  menu: (provided) => ({
    ...provided,
    maxHeight: "150px",
    overflowY: "auto",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "150px",
    overflowY: "auto",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#eb3a22"
      : state.isFocused
      ? "#fdebe9"
      : "transparent",
    color: state.isSelected ? "white" : "inherit",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: state.isSelected ? "#eb3a22" : "#fdebe9",
    },
  }),
};

export const getStatusColor = (status) => {
  let color = "danger";
  if (status === "IN_PROGRESS") {
    color = "primary";
  } else if (status === "DONE") {
    color = "success";
  } else if (status === "PENDING") {
    color = "warning";
  }
  return color;
};

export const getDaySuffix = (num) => {
  var array = ("" + num).split("").reverse(); // E.g. 123 = array("3","2","1")

  if (array[1] != "1") {
    // Number is not in the teens
    switch (array[0]) {
      case "1":
        return num + "st";
      case "2":
        return num + "nd";
      case "3":
        return num + "rd";
    }
  }

  return num + " th";
};

export const getParams = (filters, currentPage, sizePerPage) => {
  let param = "";
  let offset = (currentPage - 1) * sizePerPage;
  param += `?limit=${sizePerPage}&offset=${offset}`;

  if (filters.search) {
    param += `&query=${filters.search}`;
  }

  if (filters.user_id) {
    param += `&user_id=${filters.user_id}`;
  }
  if (filters.user_type) {
    param += `&user_type=${filters.user_type}`;
  }

  if (filters.dues) {
    param += `&dues=${filters.dues}`;
  }
  if (filters.name) {
    param += `&name=${filters.name}`;
  }
  if (filters.credit_users) {
    param += `&credit_users=${filters.credit_users == "1" ? true : false}`;
  }
  if (filters.product_status) {
    param += `&is_active=${filters.product_status == "1" ? true : false}`;
  }
  if (filters.building_status) {
    param += `&is_active=${filters.building_status == "1" ? true : false}`;
  }
  if (filters.status) {
    param += `&is_active=${filters.status}`;
  }
  if (filters.on_duty) {
    param += `&on_duty=${filters.on_duty}`;
  }
  if (filters.status_) {
    param += `&is_active=${filters.status_}`;
  }

  if (filters.state) {
    param += `&state=${filters.state}`;
  }
  if (filters.city) {
    param += `&city=${filters.city}`;
  }
  if (filters.area) {
    param += `&area=${filters.area}`;
  }
  if (filters.tapri) {
    param += `&tapri=${filters.tapri}`;
  }

  if (filters.type) {
    param += `&type=${filters.type}`;
  }
  if (filters.batchNo) {
    param += `&batch_no=${filters.batchNo}`;
  }

  if (filters.line_graph_daterange) {
    param += `&line_graph_daterange=${filters.line_graph_daterange}`;
  }
  if (filters.line_graph_endDate) {
    param += `-${filters.line_graph_endDate}`;
  }

  if (filters.total_sales_daterange) {
    param += `&total_sales_daterange=${filters.total_sales_daterange}`;
  }
  if (filters.total_sales_endDate) {
    param += `-${filters.total_sales_endDate}`;
  }

  if (filters.earning_summary_daterange) {
    param += `&earning_summary_daterange=${filters.earning_summary_daterange}`;
  }
  if (filters.earning_summary_endDate) {
    param += `-${filters.earning_summary_endDate}`;
  }

  if (filters.order_summary_daterange) {
    param += `&order_summary_daterange=${filters.order_summary_daterange}`;
  }
  if (filters.order_summary_endDate) {
    param += `-${filters.order_summary_endDate}`;
  }

  if (filters.startDate) {
    param += `&daterange=${filters.startDate}`;
  }
  if (filters.endDate) {
    param += `-${filters.endDate}`;
  }

  if (filters.container) {
    param += `&container=${filters.container}`;
  }

  if (filters.building) {
    param += `&building=${filters.building}`;
  }
  if (filters.wing) {
    param += `&wing=${filters.wing}`;
  }
  if (filters.floor) {
    param += `&floor=${filters.floor}`;
  }
  if (filters.office) {
    param += `&office=${filters.office}`;
  }

  if (filters.product) {
    param += `&product_id=${filters.product}`;
  }
  if (filters.product_name) {
    param += `&product=${filters.product_name}`;
  }

  if (filters.mobile_no) {
    param += `&mobile_number=${filters.mobile_no}`;
  }
  if (filters.phone_number) {
    param += `&phone_number=${filters.phone_number}`;
  }

  if (filters.business) {
    param += `&business=${filters.business}`;
  }
  if (filters.payment_settled) {
    param += `&payment_settled=${filters.payment_settled}`;
  }
  if (filters.is_on_tap) {
    param += `&is_on_tap=${filters.is_on_tap}`;
  }

  if (filters.minutes) {
    param += `&minutes=${filters.minutes}`;
  }

  if (filters.id) {
    param += `&id=${filters.id}`;
  }

  if (filters.delivery_boy_id) {
    param += `&delivery_boy_id=${filters.delivery_boy_id}`;
  }
  if (filters.delivery_boy) {
    param += `&delivery_boy=${filters.delivery_boy}`;
  }

  if (filters.order_type) {
    param += `&order_type=${filters.order_type}`;
  }
  if (filters.order_status) {
    param += `&order_status=${filters.order_status}`;
  }
  if (filters.payment_method) {
    param += `&payment_method=${filters.payment_method}`;
  }

  if (filters.customer_name) {
    param += `&customer_name=${filters.customer_name}`;
  }

  if (filters.sort_by_delivery_time) {
    param += `&sort_by_delivery_time=${filters.sort_by_delivery_time}`;
  }
  if (filters.delayed_order) {
    param += `&delayed_order=${filters.delayed_order}`;
  }
  if (filters.filter_value) {
    param += `&filter_value=${filters.filter_value}`;
  }
  if (filters.filter_by_type) {
    param += `&filter_by_type=${filters.filter_by_type}`;
  }
  if (filters.ratio_value) {
    param += `&ratio_value=${filters.ratio_value}`;
  }
  if (filters.duration) {
    param += `&duration=${filters.duration}`;
  }
  if (filters.exclude_saturday) {
    param += `&exclude_saturday=${filters.exclude_saturday}`;
  }
  if (filters.exclude_sunday) {
    param += `&exclude_sunday=${filters.exclude_sunday}`;
  }
  if (filters.compare_with_start_date) {
    param += `&compare_with_start_date=${filters.compare_with_start_date}`;
  }
  if (filters.compare_with_end_date) {
    param += `&compare_with_end_date=${filters.compare_with_end_date}`;
  }

  return param;
};

export const getBuildingByArea = async (area) => {
  return getApiData(`building/v1/?area=${area}`).then((res) => {
    if (res.status) {
      let building = [];
      res.data.map((item) => {
        building = [...building, { label: item.name, value: item.id }];
      });
      return building;
    }
  });
};

export const getAllBuildingsByArea = async (area) => {
  return getApiData(`cluster/v1/buildings/?area=${area}`).then((res) => {
    if (res.status) {
      let building = [];
      res.data.map((item) => {
        building = [...building, { label: item.name, value: item.id }];
      });
      return building;
    }
  });
};

export const getTapriByArea = async (area) => {
  return getApiData(`business/v1/?area=${area}`).then((res) => {
    if (res.status) {
      let tapri = [];
      res.data.map((item) => {
        tapri = [...tapri, { label: item.first_name, value: item.id }];
      });
      return tapri;
    }
  });
};

export const getAreaByCity = async (city, state) => {
  return getApiData(
    `administration/v1/areas/?city=${city}&state=${state}`
  ).then((res) => {
    if (res.status) {
      let area = [];
      res.data.map((item) => {
        area = [...area, { label: item.name, value: item.id }];
      });
      return area;
    }
  });
};

export const getProductList = async () => {
  return getApiData("v1/products/?limit=1000").then((res) => {
    if (res.status) {
      let array = [];
      res.data.map((item) => {
        array = [...array, { label: item.name, value: item.name }];
      });
      return array;
    }
  });
};

export const getStateList = async () => {
  return getApiData("administration/v1/location").then((res) => {
    if (res.status) {
      let state = [];
      let location = res.data.location;
      Object.keys(location).map((item) => {
        state = [...state, { label: item, value: item, city: location[item] }];
      });
      return state;
    } else {
      let state = [];
      let dummyData = {
        location: {
          Gujarat: ["Vadodara", "Ahmedabad"],
        },
      };
      let location = dummyData.location;
      Object.keys(location).map((item) => {
        state = [...state, { label: item, value: item, city: location[item] }];
      });
      return state;
    }
  });
};

export const getCategoryList = async () => {
  let dummyData = {
    category: {
      Cafe: ["Cafe", "Restaurant"],
      Restaurant: ["Cafe-1", "Restaurant-1"],
      Groceries: ["Medical"],
      "Retail shops": ["Apparels", "Medical"],
    },
  };

  let rootCategory = [];
  let category = dummyData.category;
  Object.keys(category).map((item) => {
    rootCategory = [
      ...rootCategory,
      { label: item, value: item, subCategory: category[item] },
    ];
  });
  return rootCategory;
};

export const module = {
  options: [],
  header: [
    navigator.platform,
    navigator.userAgent,
    navigator.appVersion,
    navigator.vendor,
    window.opera,
  ],
  dataos: [
    { name: "Windows Phone", value: "Windows Phone", version: "OS" },
    { name: "Windows", value: "Win", version: "NT" },
    { name: "iPhone", value: "iPhone", version: "OS" },
    { name: "iPad", value: "iPad", version: "OS" },
    { name: "Kindle", value: "Silk", version: "Silk" },
    { name: "Android", value: "Android", version: "Android" },
    { name: "PlayBook", value: "PlayBook", version: "OS" },
    { name: "BlackBerry", value: "BlackBerry", version: "/" },
    { name: "Macintosh", value: "Mac", version: "OS X" },
    { name: "Linux", value: "Linux", version: "rv" },
    { name: "Palm", value: "Palm", version: "PalmOS" },
  ],
  databrowser: [
    { name: "Chrome", value: "Chrome", version: "Chrome" },
    { name: "Firefox", value: "Firefox", version: "Firefox" },
    { name: "Safari", value: "Safari", version: "Version" },
    { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
    { name: "Opera", value: "Opera", version: "Opera" },
    { name: "BlackBerry", value: "CLDC", version: "CLDC" },
    { name: "Mozilla", value: "Mozilla", version: "Mozilla" },
  ],
  init: function () {
    var agent = this.header.join(" "),
      os = this.matchItem(agent, this.dataos),
      browser = this.matchItem(agent, this.databrowser);

    return { os: os, browser: browser };
  },
  matchItem: function (string, data) {
    var i = 0,
      j = 0,
      regex,
      regexv,
      match,
      matches,
      version;

    for (i = 0; i < data.length; i += 1) {
      regex = new RegExp(data[i].value, "i");
      match = regex.test(string);
      if (match) {
        regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
        matches = string.match(regexv);
        version = "";
        if (matches) {
          if (matches[1]) {
            matches = matches[1];
          }
        }
        if (matches) {
          matches = matches.split(/[._]+/);
          for (j = 0; j < matches.length; j += 1) {
            if (j === 0) {
              version += matches[j] + ".";
            } else {
              version += matches[j];
            }
          }
        } else {
          version = "0";
        }
        return {
          name: data[i].name,
          version: parseFloat(version),
        };
      }
    }
    return { name: "unknown", version: 0 };
  },
};

export const formateAmount = (num, removeSign) => {
  if (num) {
    // var input = amount;
    var n1, n2;
    num = num + "" || "";
    // works for integer and floating as well
    n1 = num.split(".");
    n2 = n1[1] || null;
    n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
    num = n2 ? n1 + "." + n2 : n1;
    if (removeSign) {
      return num;
    }
    return "₹ " + num;
  } else {
    if (removeSign) {
      return 0;
    }
    return "₹ " + 0;
  }
};

export const formateSecondesToMinute = (secondes) => {
  const minutes = Math.floor(secondes / 60);

  // 👇️ get remainder of seconds
  const seconds = secondes % 60;

  function padTo2Digits(num, type) {
    let value = num.toString();
    if (value.includes(".")) {
      value = value.split(".")[0];
    }
    if (type === "min" && value.length === 1) {
      value = "0" + value;
    } else if (type === "sec" && value.length === 1) {
      value = value + "0";
    }
    return value;
  }

  return `${padTo2Digits(minutes, "min")}:${padTo2Digits(seconds, "sec")}`;
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const isOldTimeLessthenToNewTime = (oldTime, newTime) => {
  const oldATDate = new Date(oldTime);
  const newAtDate = new Date(newTime);

  return oldATDate < newAtDate;
};

export const DateRangeSettings = {
  autoUpdateInput: false,
  ranges: {
    Today: [moment().toDate(), moment().toDate()],
    Yesterday: [
      moment().subtract(1, "days").toDate(),
      moment().subtract(1, "days").toDate(),
    ],
    "Last 7 Days": [moment().subtract(6, "days").toDate(), moment().toDate()],
    "Last 30 Days": [moment().subtract(29, "days").toDate(), moment().toDate()],
    "This Month": [
      moment().startOf("month").toDate(),
      moment().endOf("month").toDate(),
    ],
    "Last Month": [
      moment().subtract(1, "month").startOf("month").toDate(),
      moment().subtract(1, "month").endOf("month").toDate(),
    ],
  },
};

export const WeekRangeSettings = {
  autoUpdateInput: false,
  ranges: {
    "This Week": [
      moment().startOf("isoWeek").toDate(),
      moment().endOf("isoWeek").toDate(),
    ],
    "Last Week": [
      moment().subtract(1, "week").startOf("isoWeek").toDate(),
      moment().subtract(1, "week").endOf("isoWeek").toDate(),
    ],
  },
  locale: {
    firstDay: 1, // Week starts on Monday
  },
};

export const chartLoading = (loading) => {
  return {
    text: loading ? "Loading..." : "",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: "#000000",
      fontSize: "14px",
      fontFamily: "Helvetica",
    },
  };
};

export const chartColors = [
  "#2E93fA",
  "#66DA26",
  "#FFA800",
  "#546E7A",
  "#E91E63",
  "#FF9800",
  "#8950FC",
  "#6993FF",
  "#1BC5BD",
  "#F44336",
  "#9C27B0",
];

export const orderTypeList = [
  {
    label: "Scheduled",
    value: "1",
  },
  {
    label: "Manual",
    value: "2",
  },
];

export const orderStatusList = [
  {
    label: "Delivered",
    value: "1",
  },
  {
    label: "Cancelled",
    value: "2",
  },
  {
    label: "Pending",
    value: "3",
  },
  {
    label: "In Process ",
    value: "4",
  },
];

export const paymentMethods = [
  { value: "1", label: "Online" },
  { value: "2", label: "Pay on Delivery" },
  { value: "3", label: "Business credit" },
  { value: "4", label: "Credit" },
];

export const timeSlots = [
  { value: 0, label: "12:00 AM" },
  { value: 1, label: "12:30 AM" },
  { value: 2, label: "1:00 AM" },
  { value: 3, label: "1:30 AM" },
  { value: 4, label: "2:00 AM" },
  { value: 5, label: "2:30 AM" },
  { value: 6, label: "3:00 AM" },
  { value: 7, label: "3:30 AM" },
  { value: 8, label: "4:00 AM" },
  { value: 9, label: "4:30 AM" },
  { value: 10, label: "5:00 AM" },
  { value: 11, label: "5:30 AM" },
  { value: 12, label: "6:00 AM" },
  { value: 13, label: "6:30 AM" },
  { value: 14, label: "7:00 AM" },
  { value: 15, label: "7:30 AM" },
  { value: 16, label: "8:00 AM" },
  { value: 17, label: "8:30 AM" },
  { value: 18, label: "9:00 AM" },
  { value: 19, label: "9:30 AM" },
  { value: 20, label: "10:00 AM" },
  { value: 21, label: "10:30 AM" },
  { value: 22, label: "11:00 AM" },
  { value: 23, label: "11:30 AM" },
  { value: 24, label: "12:00 PM" },
  { value: 25, label: "12:30 PM" },
  { value: 26, label: "1:00 PM" },
  { value: 27, label: "1:30 PM" },
  { value: 28, label: "2:00 PM" },
  { value: 29, label: "2:30 PM" },
  { value: 30, label: "3:00 PM" },
  { value: 31, label: "3:30 PM" },
  { value: 32, label: "4:00 PM" },
  { value: 33, label: "4:30 PM" },
  { value: 34, label: "5:00 PM" },
  { value: 35, label: "5:30 PM" },
  { value: 36, label: "6:00 PM" },
  { value: 37, label: "6:30 PM" },
  { value: 38, label: "7:00 PM" },
  { value: 39, label: "7:30 PM" },
  { value: 40, label: "8:00 PM" },
  { value: 41, label: "8:30 PM" },
  { value: 42, label: "9:00 PM" },
  { value: 43, label: "9:30 PM" },
  { value: 44, label: "10:00 PM" },
  { value: 45, label: "10:30 PM" },
  { value: 46, label: "11:00 PM" },
  { value: 47, label: "11:30 PM" },
];

export const daySlots = [
  { value: 0, label: "Monday" },
  { value: 1, label: "Tuesday" },
  { value: 2, label: "Wednesday" },
  { value: 3, label: "Thursday" },
  { value: 4, label: "Friday" },
  { value: 5, label: "Saturday" },
  { value: 6, label: "Sunday" },
];

export const orderTimeSlots = [
  { label: "5 Minutes ago", value: "5" },
  { label: "10 Minutes ago", value: "10" },
  { label: "15 Minutes ago", value: "15" },
  { label: "20 Minutes ago", value: "20" },
  { label: "25 Minutes ago", value: "25" },
  { label: "30 Minutes ago", value: "30" },
  { label: "35 Minutes ago", value: "35" },
  { label: "40 Minutes ago", value: "40" },
  { label: "45 Minutes ago", value: "45" },
  { label: "50 Minutes ago", value: "50" },
  { label: "55 Minutes ago", value: "55" },
  { label: "60 Minutes ago", value: "60" },
];
