import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { convertBase64, daySlots, validEmail } from "../../config";
import { getApiData } from "../../Services";
import * as auth from "../Auth";
import { gradientColors } from "../../../gradientColor";
import { API_URL, timeSlots } from "../../config";
import { GeneralDropdown } from "../../components";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import imageCompression from "browser-image-compression";

function PersonaInformation(props) {
  // Fields
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [gstRate, setGstRate] = useState("");
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [contact, setContact] = useState("");

  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  const [openTime, setOpenTime] = useState({});
  const [closeTime, setCloseTime] = useState({});
  const [minuteSlots, setMinuteSlots] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [temporarilyClosed, setTemporarilyClosed] = useState(false);
  const [businessID, setBusinessId] = useState(false);

  const [workingDays, setWorkingDays] = useState([]);

  const user = useSelector((state) => state.auth.user, shallowEqual);

  useEffect(() => {
    getUserInfo();
    console.log("user >>>>", user);
  }, []);

  const changeStatus = async () => {
    // // setLoading(true);
    const body = {
      business: businessID,
    };
    return await getApiData(
      `business/v1/temporarily-closed/`,
      "post",
      body
    ).then((res) => {
      toast.dismiss();
      if (!res.status) {
        toast.error(res.message);
      } else {
        toast.success(res.message);
        getUserInfo();
      }
    });
  };

  const getUserInfo = () => {
    getApiData("business/v1/profile/").then((res) => {
      setLoading(false);
      if (res.status) {
        const data = res.data;
        setBusinessId(data.id);
        setTemporarilyClosed(data.temporarily_closed);
        setName(data.first_name);
        setEmail(data.email);
        setContact(data.phone_number);
        setGstNumber(data.gst_number);
        setGstRate(data.gst_rate);
        setImagePreview(data.profile_image ? data.profile_image : "");

        if (res.default_slots) {
          const mappedSlots = Object.entries(res.default_slots).map(
            ([key, value]) => ({
              id: key,
              timeSlot: value,
            })
          );

          setMinuteSlots(mappedSlots);

          if (data.slots) {
            const mappedSelectedSlots = Object.entries(data.slots).map(
              ([key, value]) => ({
                id: key,
                timeSlot: value,
              })
            );
            mappedSlots.forEach((slot) => {
              if (
                mappedSelectedSlots.some(
                  (selectedSlot) => selectedSlot.id === slot.id
                )
              ) {
                selectedItems.push(slot.id);
              }
            });
          }
        }

        for (const timeRange in data.opening_time) {
          const value = data.opening_time[timeRange];
          setOpenTime({ label: timeRange, value: value });
        }
        for (const timeRange in data.closing_time) {
          const value = data.closing_time[timeRange];
          setCloseTime({ label: timeRange, value: value });
        }

        if (data.working_days) {
          let selectedDays = daySlots.filter((item) =>
            data.working_days.includes(item.value)
          );
          setWorkingDays(selectedDays);
        }
      } else {
        // toast.error(res.message);
      }
    });
  };

  const handleSubmit = () => {
    setLoading(true);

    const valueOfDays = workingDays.map((item) => item.value);
    const workingDaysString = JSON.stringify(valueOfDays);

    const valueOfMinutes = selectedItems.map((str) => parseInt(str, 10));
    const minutesArray = JSON.stringify(valueOfMinutes);

    const body = new FormData();
    body.append("first_name", name);
    body.append("profile_image", file ? file : imagePreview);
    body.append("email", email);
    body.append("opening_time", openTime.value);
    body.append("closing_time", closeTime.value);
    body.append("working_days", workingDaysString);
    body.append("slots", minutesArray);
    body.append("gst_number", gstNumber);
    body.append("gst_rate", gstRate);

    let dataToken = JSON.parse(localStorage.getItem("persist:userInfo"));
    let token = `${JSON.parse(dataToken.authToken)}`;

    fetch(`${API_URL}business/v1/profile/`, {
      body: body,
      method: "POST",
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        setLoading(false);
        if (res.status) {
          res.json().then((data) => {
            if (data.status) {
              toast.success(data.message);
              getUserInfo();
            } else {
              toast.error(data.message);
            }
          });
        }
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!isValidEmail) {
      toast.error("Please enter valid email");
    } else if (contact.length < 10) {
      toast.error("Please enter valid contact number");
    } else if (user?.user_type !== "admin") {
      console.log("openTime >>>", openTime);
      console.log("closeTime >>>", closeTime);
      if (openTime.length == 0) {
        toast.error("Please select open time");
      } else if (closeTime.length == 0) {
        toast.error("Please select close time");
      } else {
        handleSubmit();
      }
    } else {
      handleSubmit();
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const compressedFile = await compressImage(selectedFile);
      const finalFile = new File([compressedFile], selectedFile.name, {
        type: compressedFile.type,
      });

      setFile(finalFile);

      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(finalFile);
    }
  };

  const compressImage = async (image) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedImage = await imageCompression(image, options);
      return compressedImage;
    } catch (error) {
      console.error("Image compression failed:", error);
      return image; // Return original image if compression fails
    }
  };

  const handleItemClick = (item) => {
    const isSelected = selectedItems.includes(item.id);

    const updatedSelectedItems = isSelected
      ? selectedItems.filter((itemId) => itemId !== item.id)
      : [...selectedItems, item.id];

    setSelectedItems(updatedSelectedItems);
  };

  return (
    <>
      <form className="card card-custom card-stretch">
        {loading && <ModalProgressBar />}

        {/* begin::Header */}
        <div className="card-header py-3">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label font-weight-bolder text-dark">
              Personal Information
            </h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">
              Update your personal informaiton
            </span>
          </div>
          <div className="card-toolbar">
            <button
              onClick={(e) => onSubmit(e)}
              className="custom-button-danger mr-2"
            >
              Save Changes
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Form */}
        <div className="form">
          {/*begin::Body*/}
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12">
                {/* <h5 class="font-weight-bolder mb-6 text-dark">Customer Info</h5> */}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-2 col-lg-3 col-form-label">
                Profile Piture
              </label>
              <div className="col-lg-9 col-xl-6">
                {imagePreview ? (
                  <>
                    <div
                      className="image-input image-input-outline"
                      id="kt_profile_avatar"
                      style={{
                        backgroundImage: "url(assets/media/users/blank.png)",
                      }}
                    >
                      <div
                        className="image-input-wrapper"
                        style={{
                          backgroundImage: `url("${imagePreview}")`,
                        }}
                      />
                      <label
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="change"
                        data-toggle="tooltip"
                        title
                        data-original-title="Change avatar"
                      >
                        <i className="fa fa-pen icon-sm text-muted" />
                        <input
                          type="file"
                          name="profile_avatar"
                          accept=".png, .jpg, .jpeg"
                          // onChange={(e) => handleFileRead(e)}
                          onChange={handleFileChange}
                        />
                        <input type="hidden" name="profile_avatar_remove" />
                      </label>

                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="cancel"
                        data-toggle="tooltip"
                        title
                        data-original-title="Cancel avatar"
                      >
                        <i className="ki ki-bold-close icon-xs text-muted" />
                      </span>
                      <span
                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                        data-action="remove"
                        data-toggle="tooltip"
                        title="Remove avatar"
                        onClick={() => {
                          setFile(null);
                          setImagePreview("");
                        }}
                      >
                        <i className="ki ki-bold-close icon-xs text-muted" />
                      </span>
                    </div>
                    <span className="form-text text-muted">
                      Allowed file types: png, jpg, jpeg.
                    </span>
                  </>
                ) : (
                  <>
                    <label
                      htmlFor="imageFile"
                      class="symbol symbol-lg-35 symbol-25 symbol-light-success"
                    >
                      <span
                        class="symbol-label  font-weight-bold text-uppercase"
                        style={{
                          height: "100px",
                          width: "100px",
                          fontSize: "2.2rem",
                          background: `linear-gradient(to top, ${gradientColors.startColor}, ${gradientColors.endColor})`,
                          color: "#FFF",
                        }}
                      >
                        {name[0]}
                      </span>
                    </label>
                    <input
                      type="file"
                      id="imageFile"
                      accept=".png, .jpg, .jpeg"
                      style={{ display: "none" }}
                      // onChange={(e) => handleFileRead(e)}
                      onChange={handleFileChange}
                    />
                    <label htmlFor="imageFile">
                      <a className="ml-2">Change avatar</a>
                    </label>
                  </>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-2 col-lg-3  col-form-label">Name</label>
              <div className="col-lg-9 col-xl-4 ">
                <input
                  className="form-control form-control-lg form-control-solid"
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <h5 className="font-weight-bolder mt-10 mb-6 text-dark">
                    GST Info
                  </h5>
                </div>
              </div>
              <div className="row ">
                <div className="col-md-6 ">
                  <div className="form-group row">
                    <label className="col-xl-4 col-lg-4 col-form-label">
                      GST Number
                    </label>
                    <div className="col-lg-8 col-xl-8">
                      <div className="input-group input-group-lg input-group-solid">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent">
                            <i className="fa fa-id-card" />
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          value={gstNumber}
                          onChange={(e) => {
                            setGstNumber(e.target.value);
                          }}
                          placeholder="GST Number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6 ">
                  <div className="form-group row">
                    <label className="col-xl-4 col-lg-4 col-form-label">
                      GST Rate
                    </label>
                    <div className="col-lg-8 col-xl-8">
                      <div className="input-group input-group-lg input-group-solid">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <i className="fa fa-percent" />
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control form-control-lg form-control-solid"
                          value={gstRate}
                          onChange={(e) => {
                            setGstRate(e.target.value);
                          }}
                          placeholder="GST Rate"
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h5 className="font-weight-bolder mt-10 mb-6 text-dark">
                  Contact Info
                </h5>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-6 ">
                <div className="form-group row">
                  <label className="col-xl-4 col-lg-4 col-form-label">
                    Contact Number
                  </label>
                  <div className="col-lg-8 col-xl-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <div className="input-group-prepend bg-transparent">
                        <span className="input-group-text bg-transparent">
                          <i className="fa fa-phone" />
                        </span>
                      </div>
                      <input
                        type="number"
                        className="form-control form-control-lg form-control-solid"
                        value={contact}
                        onChange={(e) => {
                          if (e.target.value.length <= 10)
                            setContact(e.target.value);
                        }}
                        placeholder="Phone"
                        required
                        style={{ "-moz-appearance": "textfield" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="form-group row">
                  <label className="col-xl-4 col-lg-4 col-form-label">
                    Email Address
                  </label>
                  <div className="col-lg-8 col-xl-8">
                    <div className="input-group input-group-lg input-group-solid">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-at" />
                        </span>
                      </div>
                      <input
                        type="email"
                        className="form-control form-control-lg form-control-solid"
                        value={email}
                        onChange={(e) => {
                          // setEmail(e.target.value);

                          const { value } = e.target;
                          setEmail(value);
                          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                          setIsValidEmail(emailPattern.test(value));
                        }}
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {user?.user_type !== "admin" && (
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <h5 className="font-weight-bolder mt-10 mb-6 text-dark">
                      Opening/Closing Time
                    </h5>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6 ">
                    <div className="form-group row align-items-center">
                      <label
                        className="col-lg-4 col-form-label "
                        style={{
                          fontSize: "0.95rem",
                        }}
                      >
                        Temporarily Closed
                      </label>
                      <div className="col-lg-7 pl-0">
                        <div
                          className="form-check  form-switch form-check-custom form-check-solid"
                          style={{
                            paddingLeft: "1rem",
                          }}
                        >
                          <input
                            className="form-check-input h-25px w-50px"
                            type="checkbox"
                            onChange={(e) => {
                              toast.loading("updating...");
                              changeStatus();
                            }}
                            checked={temporarilyClosed}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitch20x30"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6 ">
                    <div className="form-group row">
                      <label className="col-xl-4 col-lg-4 col-form-label required">
                        Open Time
                      </label>
                      <div className="col-lg-8 col-xl-8">
                        <GeneralDropdown
                          placeholder="Select Time"
                          selectedOption={openTime}
                          list={timeSlots}
                          handleChange={(item) => {
                            setOpenTime(item);
                          }}
                          inModal
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="form-group row">
                      <label className="col-xl-4 col-lg-4 col-form-label required">
                        Close Time
                      </label>
                      <div className="col-lg-8 col-xl-8">
                        <GeneralDropdown
                          placeholder="Select Time"
                          selectedOption={closeTime}
                          list={timeSlots}
                          handleChange={(item) => {
                            setCloseTime(item);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <div className="form-group row">
                      <label className="col-form-label">
                        Select Time Slots
                      </label>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="row">
                      {minuteSlots.map((item, index) => (
                        <a
                          href
                          key={index}
                          className="col-md-3"
                          onClick={() => handleItemClick(item)}
                        >
                          <div
                            className={
                              selectedItems.includes(item.id)
                                ? `py-2 px-2 bg-primary text-center text-light mb-2`
                                : `py-2 px-2 bg-gray-light text-center text-dark mb-2`
                            }
                            style={{ borderRadius: 3 }}
                          >
                            <span className="float-center">
                              {item.timeSlot}
                            </span>
                          </div>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <h5 className="font-weight-bolder mt-10 mb-6 text-dark">
                    Opening/Closing Days
                  </h5>
                  <div className="col-md-6 ">
                    <div className="form-group row">
                      <label className="col-xl-4 col-lg-4 col-form-label">
                        Working Days
                      </label>
                      <div className="col-lg-8 col-xl-8">
                        <GeneralDropdown
                          placeholder="Select Day"
                          selectedOption={
                            workingDays.length > 0 ? workingDays : []
                          }
                          list={daySlots}
                          handleChange={(item) => {
                            setWorkingDays(item);
                          }}
                          inModal
                          isMulti={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
}

export default connect(null, auth.actions)(PersonaInformation);
